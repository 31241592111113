@context (devicelight > 50) {
  body {
    color: #262727;
  }
  /* ELEMENTS */
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    color: #000000;
  }
  h1 {
    color: #a10c54;
  }
  h2 {
    color: #a10c54;
  }
  h3 {
    color: #fff;
  }
  a {
    color: #a10c54;
  }
  a:hover {
    color: #a10c54;
  }
  .lead {
    background-color: #e6e7e8;
  }
  .breadcrumb {
    background-color: transparent;
  }
  .breadcrumb li a {
    color: #262727;
  }
  .btn-default {
    color: #262727;
  }
  .btn-view,
  .download-button {
    background-color: #007d87;
    color: #fff !important;
  }
  .btn-hollow {
    border: 2px #a10c54 solid;
    color: #a10c54;
  }
  .btn-hollow:hover {
    color: #fff;
    background-color: #a10c54;
  }
  .donate-button {
    color: #fff;
    background-color: #007d87;
  }
  .donate-button:hover {
    background-color: #211a37;
  }
  .donate-page-button {
    background-color: #007d87;
    color: #fff;
  }
  .donate-page-button:hover {
    background-color: #211a37;
  }
  .contact-button {
    color: #fff;
    background-color: #a10c54;
  }
  .contact-button:hover {
    background-color: #d11a72;
  }
  /* DONATE DROPDOWN */
  .btn-dropdown .well {
    background: #fdf070;
  }
  .btn-dropdown .form-group.select .form-control {
    color: #4c4d4d;
  }
  .btn-dropdown .form-group .form-control {
    background: #fcf6ba;
  }
  .btn-dropdown hr {
    border-color: #58595b;
  }
  .open > .dropdown-toggle.btn-default:active,
  .open > .dropdown-toggle.btn-default.active,
  .open > .dropdown-toggle.btn-default:focus,
  .open > .dropdown-toggle.btn-default.focus,
  .btn-dropdown .btn-default:active,
  .btn-dropdown .btn-default.active,
  .btn-dropdown .btn-default:focus,
  .btn-dropdown .btn-default.focus,
  .btn-dropdown .open > .dropdown-toggle.btn-default {
    background: #007d87;
  }
  .btn-dropdown .btn-default:hover,
  .btn-dropdown .btn-default.hover {
    background: #007d87;
  }
  .btn-dropdown .btn-primary,
  .btn-dropdown .btn-primary:active,
  .btn-dropdown .btn-primary:focus {
    background: #ec297f;
  }
  .btn-dropdown .form-group input,
  .btn-dropdown .form-group textarea {
    color: #262727;
  }
  .btn-dropdown .form-group ::-webkit-input-placeholder,
  .btn-dropdown .form-group ::-moz-placeholder,
  .btn-dropdown .form-group :-ms-input-placeholder,
  .btn-dropdown .form-group ::placeholder {
    color: #262727;
  }
  /* MODAL */
  .dudley-modal .dudley-form .form-group .form-control {
    background-color: #f3f3f3;
  }
  .dudley-modal .modal-content .modal-title {
    color: #a10c54;
  }
  /* MAIN NAV */
  .navbar {
    background: #a10c54;
  }
  .navbar .navbar-toggle .icon-bar {
    background-color: #a10c54;
  }
  .navbar .navbar-collapse .navbar-nav {
    background-color: #a10c54;
  }
  .navbar .navbar-collapse .navbar-nav li a:hover, .navbar .navbar-collapse .navbar-nav li a:active, .navbar .navbar-collapse .navbar-nav li a:focus {
    background-color: #d11a72;
  }
  .navbar .navbar-collapse .navbar-nav .dropdown-menu {
    background: #d11a72;
  }
  .navbar .navbar-collapse .navbar-nav .open a {
    background: #d11a72;
  }
  .navbar .navbar-collapse .navbar-nav .open .dropdown-menu li a:hover {
    background: #a10c54;
  }
  .navbar.full-nav .full-nav-container .full-nav-section {
    background-color: #a10c54;
  }
  .navbar.full-nav .full-nav-container .full-nav-section li a:hover, .navbar.full-nav .full-nav-container .full-nav-section li a:active, .navbar.full-nav .full-nav-container .full-nav-section li a:focus {
    background-color: #d11a72;
  }
  .footer-social,
  .header-details {
    color: #a10c54;
  }
  .footer-social a.round-icon.twitter,
  .header-details a.round-icon.twitter {
    background-color: #2397bc;
  }
  .footer-social a.round-icon.facebook,
  .header-details a.round-icon.facebook {
    background-color: #3B5998;
  }
  .footer-social a.round-icon.message,
  .header-details a.round-icon.message {
    background-color: #211a37;
  }
  .footer-social a.round-icon.contrast, .footer-social a.round-icon.text, .footer-social a.round-icon:hover,
  .header-details a.round-icon.contrast,
  .header-details a.round-icon.text,
  .header-details a.round-icon:hover {
    background-color: #a10c54;
  }
  .home-intro,
  .intro {
    background-color: #e6e7e8;
  }
  .footer-social a.round-icon:hover {
    color: #000000;
    background-color: #007d87;
  }
  /* BANNER */
  .banner .item .carousel-caption {
    background: rgba(180, 18, 100, 0.9);
    opacity: 0.9;
  }
  .simplified-banner .item .carousel-caption {
    background: rgba(180, 18, 100, 0.9);
    opacity: 0.9;
  }
  .boxed-content {
    background-color: #e6e7e8;
  }
  .boxed-content .detail {
    color: #a10c54;
  }
  .boxed-content .detail .header {
    color: #000000;
  }
  a.middle-boxes .image-text {
    border: 2px #a10c54 solid;
    color: #a10c54;
  }
  a.middle-boxes:hover img {
    opacity: 0.6;
  }
  a.middle-boxes:hover .image-text {
    color: #fff;
    background-color: #a10c54;
  }
  .blue-box h3,
  .green-box h3 {
    background-color: #a10c54;
  }
  .blue-box .item a.title,
  .green-box .item a.title {
    color: #a10c54;
  }
  .blue-box .item a.title:hover,
  .green-box .item a.title:hover {
    opacity: 0.3;
  }
  .blue-box .item .btn-read-more,
  .green-box .item .btn-read-more {
    color: #a10c54;
  }
  .blue-box h3 {
    background-color: #2397bc;
  }
  .blue-box .btn-hollow {
    border-color: #2397bc;
    color: #2397bc;
  }
  .blue-box .btn-hollow:hover {
    color: #fff;
    background: #2397bc;
  }
  .blue-box .item a.title {
    color: #2397bc;
  }
  .blue-box .item .btn-read-more {
    color: #2397bc;
  }
  /* LIST & GRID LAYOUT */
  .list .item {
    background: #e6e7e8;
  }
  .list .item .box-content .news-date {
    color: #a10c54;
  }
  .list .item .box-content a {
    color: #000000;
  }
  .grid .item h2 a {
    color: #000000;
  }
  .grid .item h2 a:hover {
    color: #a10c54;
  }
  /* DONATE PAGE */
  .donate-page-box .donate-green-box {
    background-color: #a10c54;
  }
  .donate-page-box .donate-selection {
    background-color: #e6e7e8;
  }
  /* CONTACT PAGE */
  .contact-details .clevericons {
    color: #a10c54;
  }
  /* FORM */
  .dudley-form .btn-submit {
    background-color: #a10c54;
  }
  /* FOOTER */
  .footer {
    background-color: #a10c54;
  }
  .footer .footer-content .quick-links a:hover {
    color: #007d87;
  }
  .second-footer {
    background: #a10c54;
  }
  .second-footer a {
    color: #fff;
  }
  .adaptive-toolbar {
    background-color: #a10c54;
  }
  .adaptive-toolbar .handle {
    background-color: #a10c54;
  }
  .adaptive-toolbar .content a {
    color: #fff;
  }
  .adaptive-toolbar .content .popover {
    color: #262727;
  }
  .adaptive-toolbar .content .popover .popover-title {
    color: #a10c54;
  }
  .header-details a.round-icon.contrast:after {
    content: 'C-';
  }
  .header-details a.round-icon.contrast {
    background-color: #a10c54 !important;
    color: #fff;
  }
}

/*# sourceMappingURL=app-high-contrast.css.map */
